@import '../../styles/variables';

#main-body {
  overflow-x: hidden;
}

#nav-container {
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 1000;

  #homepage-nav {
    width: 100%;
    height: 95px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 1.2em;
    position: fixed;
    background-color: $white;

    #menuToggle {
      padding-right: 15vw;

      input {
        display: none;
        width: 40px;
        height: 32px;
      }

      #menu {
        display: flex;
        flex-direction: row;

        li {
          display: flex;
        }
      }
    }

    .mintfarben-studio-logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
      padding-left: 15vw;
      padding-top: 2vh;
      z-index: 1000;
      font-family: $font-family-anton;
      font-size: 1.7em;
      text-shadow: -4px 4px $mint-green;
      margin-top: 0;
      line-height: 1em;
    }

    ul {
      margin: 0;
      font-size: 1.4em;
      font-weight: 500;
      font-family: $font-family-antonio !important;
      letter-spacing: 2px;

      li {
        width: 130px;
        justify-content: end;
        transition: 1s;

        .selected {
          border-bottom: 7px solid $mint-green-opacity-50;
        }

        a {
          width: fit-content;
          border-bottom: 7px solid $transparent;
          transition: 0.5s ease-out;
          color: $black-apple;

          &:hover {
            text-decoration: none !important;
            cursor: pointer;
            border-bottom: 7px solid $mint-green-opacity-50;
            color: $dark-grey-batman;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  #homepage-nav {
    #menu {
      height: 100vh !important;
      flex-direction: column !important;
    }
  }
}

@media (max-width: 930px) {
  #homepage-nav {
    li {
      width: 120px !important;
    }
  }
}

@media (max-width: 1250px) {
  #homepage-nav {
    #menu {
      height: 100vh !important; // displays the menu
      flex-direction: column !important;
    }
  }
}

@media (min-width: 1360px) {
  #homepage-nav {
    .mintfarben-studio-logo {
      padding-top: 1.5vh !important;
      font-size: 1.7em !important;
    }

    li {
      width: 150px !important;
    }
  }
}

@media (min-width: 1500px) {
  #homepage-nav {
    height: 110px;
  }
}

/* Hamburger menu made by Erik Terwan */
