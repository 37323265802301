@import '../../../styles/variables';

#yahtzee-config-organism-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.mini-dices-container {
  display: flex;
  position: relative;
  top: 28%;
  height: 70px;

  .mini-dices-nb-players {
    .dice {
      box-shadow: 0 0 10px $black-opacity-25;
    }

    .dice-dot {
      background-color: $very-light-blue;
    }
  }
}

#nb-players-buttons-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  margin-bottom: 20px;
}

.nb-players-selected-buttons {
  font-weight: 200;
  transition-delay: 0s;
  border-color: $light-blue;
  background-color: $light-blue;
  color: $very-light-grey;
}

.nb-players-not-selected-buttons {
  font-weight: 200;
  border-color: $very-light-green;
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: border-color;

  &:hover {
    border-color: $light-grey-2;
  }
}

.start-button {
  transition-duration: 0.3s;
  border-color: $very-light-green;
}

.start-button-disabled {
  background-color: $very-light-grey;
  color: $dark-grey;

  &:hover {
    cursor: not-allowed;
    background-color: $very-light-grey;
  }
}
