@import '../../../styles/variables';

.dice {
  width: 80px;
  height: 80px;
  position: relative;
  margin: 5px;
  border-radius: 10px;
  box-shadow: 0 0 10px $black-opacity-25;
  transition-duration: 0.8s;
  transition-property: box-shadow;
}

.dice-selection-allowed {
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px $black-opacity-70;
  }
}

.dice-container {
  transition-duration: 0.8s;
  transition-property: padding-top;
}

.selected-dices-to-reroll-container {
  padding-top: 10px;

  .dice {
    box-shadow: 0 0 10px $black-opacity-90;
  }

  //   &.roll-dices-animation {
  //     animation-name: dice-dot-animation;
  //     animation-duration: 10s;
  //   }
  // }

  // @keyframes dice-dot-animation {
  //   0% {
  //     transform: rotate(20deg);
  //   }
  //   100% {
  //     transform: rotate(180deg);
  //   }
}

.dice-dot {
  width: 15px;
  height: 15px;
  background-color: $black;
  border-radius: 50%;

  /* Dot Positioning */
  position: absolute;
  top: var(--top);
  left: var(--left);
}
