@import '../../styles/variables';

.modal-container {
  position: absolute;
  z-index: 1000;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px $black-opacity-25;
}
