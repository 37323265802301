@import '../../styles/variables';

.buttons-list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 55%;

  button {
    color: $white;
  }
}


