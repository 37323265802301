@import '../styles/variables';

#numbers-list-body {
  background-color: $dark-purple;
  font-family: $font-family-outfit;
  color: white;
}

header {
  background-color: $white;
  padding: 30px;

  h1 {
    font-weight: 400;
    font-size: 3rem;
    text-align: center;
    color: $black;
    margin: 0;
  }
}

#numbers-list-container {
  margin: 0 auto;
  width: 70%;
  height: 83vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-order-inputs {
  font-family: $font-family-outfit;
}

.list-order-buttons {
  background-color: $light-purple !important;
  border: none !important;
  transition-delay: 0s !important;
  transition-duration: 0.5s !important;
  transition-property: background-color !important;
  transition-timing-function: ease-in-out !important;

  &:hover {
    background-color: $purple-2 !important;
  }
}