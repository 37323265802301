#logos-container {
  position: relative;
  aspect-ratio: 1000 / 556;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  img {
    position: absolute;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@media (max-width: 1024px) {
  .mintfarben-logo {
    max-width: 300px;
  }
}

@media (min-width: 1360px) {
  .mintfarben-logo {
    max-width: 400px;
  }
}

/* Source: https://codepen.io/Hyperplexed/pen/KKrRLXM?editors=1111 */
