@import '../styles/variables';

#yahtzee-body {
  height: 100vh;
  font-family: $font-family-montserrat;
  color: $dark-grey;
  position: relative;

  header {
    height: 14vh;
    background-color: $offwhite;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  h1 {
    color: $dark-grey;
    letter-spacing: 0.7em;
    font-weight: 400;
    font-size: 2.4rem;
    transition-duration: 1s;
    transition-property: color;
    padding-left: 0.7em;
  }

  h2 {
    color: $dark-grey;
    letter-spacing: 0.2em;
    padding-left: 0.7em;
  }

  main {
    background: linear-gradient($offwhite, $very-light-yellow);
  }

  footer {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 15px;
    font-size: 0.8em;

    a {
      text-decoration: none;
      color: $dark-grey-batman;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: $dark-grey-batman-opacity-60;
      }
    }
  }
}

#yahtzee-game-container {
  margin: 0 auto;
  width: 100%;
  height: 86vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 1.2rem;
  }
}
