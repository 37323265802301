@import '../../../styles/variables';

.plain-button {
  font-family: $font-family-montserrat;
  padding: 4px 8px;
  border: 1px solid $light-grey;
  border-radius: 20px;
  color: $purple;
  transition-delay: 0.1s;
  transition-property: background-color;

  &:hover {
    cursor: pointer;
  }
}

.gaming-button {
  font-family: $font-family-common-pixel;
  display: flex;
  position: relative;
  color: $very-light-grey;
  background-color: $dark-orange;
  border-radius: 5px;
  box-shadow: 4px 6px $light-grey;
  padding: 3px 10px;
  transition-duration: 1s;
  transition-property: background-color;
  transition-timing-function: ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: $very-dark-orange;
  }

  &:active {
    box-shadow: 2px 2px $dark-grey;
    transform: translate(3px, 5px);
  }
}

.back-button,
.question-mark-icon,
.close-button {
  font-family: $font-family-montserrat;
  color: $very-light-blue;
  background-color: $transparent;
  padding: 10px 12px;
  border-radius: 20px;
  border: 0.5px solid $very-light-blue;
  transition-property: background-color;
  transition-duration: 0.5s;

  &:hover {
    cursor: pointer;
    border: 0.5px solid $very-light-grey;
    background-color: $very-light-grey;
    color: $dark-grey;
  }
}

.question-mark-icon {
  padding: 8px 14px !important;
}

.filling-left-to-right-button {
  background: linear-gradient(to right, $mint-green 50%, $red-watermelon 50%);
  background-position: right bottom;
  background-size: 200% 100%;
  color: $very-light-grey !important;
  border-color: $very-light-green !important;
  padding: 7px 14px !important;
  box-shadow: 0 0 10px $black-opacity-25;
  transition: background-position 0.5s ease-out, color 1s ease-out !important;

  &:hover {
    background-position: left bottom;
    color: $dark-grey !important;
  }
}
