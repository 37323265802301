@import '../../../styles/variables';

#dices-roll-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .dices-with-order-btn-container {
    display: flex;
    justify-content: flex-end;
    position: relative;

    .sort-button {
      position: absolute;
      top: 25%;
      right: -10%;
      border-color: $very-light-green;
      transition-delay: 0s;
      transition-duration: 0.5s;
      transition-property: border-color;

      &:hover {
        border-color: $light-grey-2;
      }
    }
  }

  .roll-dices-button {
    border-color: $very-light-green;
    padding: 4px 10px;
    transition-delay: 0s;
    transition-duration: 0.5s;
    transition-property: border-color;

    &:hover {
      border-color: $light-grey-2;
    }
  }

  .roll-dices-button-disabled {
    background-color: $very-light-grey;
    color: $dark-grey;

    &:hover {
      cursor: not-allowed;
      border-color: $very-light-green;
    }
  }

  .dices-selection-not-allowed {
    &:hover {
      cursor: none !important;
    }
  }
}
