$font-family-common-pixel: 'Common Pixel', sans-serif;
$font-family-mulish: 'Mulish', sans-serif;
$font-family-open-sans: 'Open Sans', sans-serif;
$font-family-outfit: 'Outfit', sans-serif;
$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-anton: 'Anton', sans-serif;
$font-family-antonio: 'Antonio', sans-serif;

$transparent: #11ffee00;

$white: #ffffff;
$offwhite: #fcf9f7;

$very-light-yellow: #efd8cb;
$very-light-yellow-2: #eee4e4;
$yellow: #f9ca24;
$yellow-turbo: #ffba08;
$yellow-turbo-opacity-75: rgba(255, 186, 8, 0.75);

$orange: #e79085;
$dark-orange: #d27366;
$very-dark-orange: #b36156;

$dark-pink: #ff7675;

$red-watermelon: rgb(255, 108, 129);
$red-watermelon-opacity-25: rgba(255, 108, 129, 0.25);
$red-watermelon-opacity-75: rgba(255, 108, 129, 0.75);
$medium-red: #e74c3c;
$dark-red: hsl(6, 69%, 36%);
$very-dark-red: #67140b;

$light-brown: #a47b76;
$brown: #703f39;

$very-light-green: rgba(222, 217, 204, 1);
$very-light-green-opacity-10: rgba(222, 217, 204, 0.1);
$very-light-green-opacity-25: rgba(222, 217, 204, 0.25);
$very-light-green-opacity-75: rgba(222, 217, 204, 0.75);
$mint-green: #95d5b2;
$mint-green-opacity-25: rgba(149, 213, 178, 0.25);
$mint-green-opacity-50: rgba(149, 213, 178, 0.5);

$very-light-purple: #9e9ef5;
$light-purple: #6161ff;
$light-purple-2: #4343f2;
$purple: #303249;
$purple-2: #1f1ff7;
$dark-purple: #191c34;

$very-light-blue: #c8ced2;
$very-light-blue-2: #d3d3d3;
$very-light-blue-3: #eeeeee;
$light-blue: #68a1c6;
$blue: #4896c9;

$very-light-grey: #e4e4e4;
$very-light-grey-2: #f7f7f8;
$light-grey: #676979;
$light-grey-2: #9193a3;
$light-grey-3: #abb6c2;
$grey: #f1f2f6;
$dark-grey: #485460;
$dark-grey-2: #273038;
$dark-grey-batman: #2f3542;
$dark-grey-batman-opacity-60: rgba(47, 53, 66, 0.6);
$dark-grey-batman-opacity-80: rgba(47, 53, 66, 0.8);

$black: black;
$black-opacity-10: rgba(0, 0, 0, 0.1);
$black-opacity-25: rgba(0, 0, 0, 0.25);
$black-opacity-50: rgba(0, 0, 0, 0.5);
$black-opacity-70: rgba(0, 0, 0, 0.7);
$black-opacity-90: rgba(0, 0, 0, 0.9);
$black-apple: #1d1d1f;
