@import '../../../styles/variables';
@font-face {
  font-family: 'San Marino Beach';
  src: url('../../../styles/SanMarinoBeach-2OOLW.ttf');
}

#yahtzee-game-organism-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  li {
    display: flex;
  }
}

#dices-container {
  display: flex;
  margin-bottom: 20px;
  min-height: 100px;
}

.small-dices-container {
  display: flex;
  justify-content: center;
  min-height: 70px;
  margin-top: 20px;
}

.small-dices-to-reroll {
  .dice {
    width: 50px;
    height: 50px;
    position: relative;
    margin: 5px;
    border-radius: 10px;
    box-shadow: 0 0 10px $black-opacity-70;

    .dice-dot {
      width: 10px;
      height: 10px;
    }
  }
}

.game-turn-info-modal {
  background-color: $very-light-blue-2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  font-weight: 300;
  text-align: center;
  margin-top: 4%;
  // top: calc(15%);
  // left: calc(33%);
  width: 410px;
  height: 70px;
  background-color: $transparent;
  box-shadow: 0 0 0px $black-opacity-25;

  h2 {
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    font-size: 1em !important;
    letter-spacing: 0.3em !important;

    span {
      font-weight: 400 !important;
      color: $dark-orange;
    }
  }

  .game-turn-header {
    display: flex;

    .paper-scissors-stone-container {
      display: flex;
      justify-content: center;

      i:nth-child(2) {
        padding: 0 10px;
      }
    }

    .close-button-game-turn-yahtzee {
      color: $dark-grey;
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 3px 6px;
      border-color: $very-light-grey;
    }

    p {
      margin-top: 0px;
    }

    p span {
      font-weight: 400;
    }
  }
}

.game-end-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
}

.game-info-modal {
  position: fixed;
  bottom: 350px;
  left: 50%;
  top: 43%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  font-size: 0.8em;
  font-weight: 300;
  height: fit-content;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: $very-light-yellow-2;

  .close-button-game-turn-yahtzee {
    padding: 3px 6px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.rename-player-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .rename-player-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
  }
}

.last-round-modal {
  width: 200px;
  text-align: center;
}

.score-card-container {
  // width: 100vw;
  // height: 100vh;
  position: absolute;

  .score-card-modal {
    position: relative;
    background-color: $very-light-blue-3;
    position: fixed;
    top: calc(1%);
    right: calc(2.2%);
    padding: 15px 20px !important;

    .score-card-header {
      display: flex;

      h1 {
        font-size: 1em !important;
        font-weight: bold !important;
        letter-spacing: 2px !important;
        margin-top: 0px !important;
      }

      .close-button-yahtzee {
        color: $dark-grey;
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 3px 6px;
      }

      .player-playing-name {
        color: $orange;
      }
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 300px;
      font-size: 0.7em;
      font-weight: 300;
      box-sizing: border-box;

      tr {
        height: 30px;
      }

      td {
        padding: 4px 6px;
      }

      @for $i from 1 through 3 {
        td:nth-child(1) {
          font-weight: 500;
        }
      }

      @for $i from 1 through 3 {
        td:nth-child(2) {
          font-size: 0.9em;
        }
      }

      @for $i from 1 through 3 {
        td:nth-child(2),
        td:nth-child(3) {
          text-align: center;
        }
      }

      .bonus {
        background-color: $very-light-grey;

        span {
          font-size: 0.9em;
        }
      }

      .subtotal {
        font-weight: 500;
        background-color: $very-light-blue-2;
      }

      .total {
        font-weight: 500;
        color: $very-light-grey;
        background-color: $dark-grey;
      }
    }

    table,
    th,
    td {
      padding: 6px;
      border: 1px solid $very-light-grey;
    }
  }
}

.instructions-modal {
  background-color: $very-light-blue-3;
  position: absolute;
  left: calc(45%);
  bottom: calc(15%);
  padding: 10px 20px !important;
  font-size: 0.9em;
  // width: 200px;
}

.game-status-modal {
  position: absolute;
  top: calc(28%);
  left: calc(2%);
  width: 240px;
  height: 130px;
  background-color: $transparent;
  font-size: 0.8em;
  border: 1px solid $black-opacity-25;
  // box-shadow: none !important;
  box-shadow: 8px 8px 2px 1px $black-opacity-25 !important;

  p {
    font-size: 1.5em;
    font-weight: 100;
    text-align: end;

    span {
      text-align: start;
      font-size: 0.7em;
      font-style: italic;
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    @for $i from 1 through 2 {
      th:nth-child(1) {
        width: 70%;
      }

      th:nth-child(2) {
        width: 30%;
      }
    }

    @for $i from 1 through 2 {
      td:nth-child(1) {
        font-size: 1.5em;
        font-weight: 100;
        text-align: end;
      }
    }

    @for $i from 1 through 2 {
      td:nth-child(2) {
        text-align: start;
        font-size: 0.8em;
        font-style: italic;
        vertical-align: bottom;
        padding-left: 4px;
      }
    }

    td {
      padding-bottom: 15px;
    }

    .big-letters-font {
      font-family: 'San Marino Beach' !important;
      color: $light-grey;
      font-size: 1.4em;
      letter-spacing: 0.06em;
    }

    .player-currently-playing-name {
      color: $orange;
    }
  }
}

.dices-result {
  font-family: $font-family-montserrat;
  font-size: 1em;
  color: $dark-grey;
  font-weight: 300;
  border: none;
}

.dices-result-to-confirm {
  box-sizing: content-box;
  width: 15px !important;
  border: 1px solid $dark-grey;
  border-radius: 15px;
  font-size: 1.1em;
  font-weight: 400 !important;
  color: $dark-orange;
  box-shadow: 2px 3px $dark-grey;
  width: 26px;

  &:active {
    box-shadow: 2px 2px $dark-grey;
    transform: translate(1px, 3px);
  }

  &:hover {
    cursor: pointer;
  }
}

.score-card-header {
  h1 {
    padding-left: 0px !important;
  }
}

.game-end-modal {
  height: fit-content;
  width: 270px;

  .game-end-content {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    text-align: center;
    line-height: 2em;

    .winner-total-score {
      font-weight: 400;
      margin: 0;
    }

    .winner-description {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ul {
      padding: 0;
    }

    li {
      line-height: 2em;
    }

    .winner-name {
      color: $blue;
      font-weight: 400;
      font-size: 1.7em;
      text-transform: uppercase;
    }

    .replay-button {
      margin-top: 22px;
    }

    .text-bold {
      font-weight: 400;
      margin: 0;
    }
  }
}

.ok-button {
  border-color: $very-light-green;
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: border-color;

  &:hover {
    border-color: $light-grey-2;
  }
}

.fa-hand,
.fa-hand-scissors,
.fa-hand-back-fist {
  transition: transform 2s ease-out;

  &:hover {
    transform: rotate(1turn);
  }
}
