@import '../styles/variables';

body {
  cursor: url('../assets/ellipse-dark-grey.png'), auto;
}

html {
  scroll-behavior: smooth;
}

#main-body {
  /* COMMON TO ALL PAGES */
  font-family: $font-family-montserrat, sans-serif;
  font-weight: 300;
  color: $black-apple;
  line-height: 1.7em;
  margin: 0 auto;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  main {
    font-size: 1.2em; // 1.2em = 19px
  }

  h1 {
    font-size: 1.8em !important; // 1.8em = 51px
  }

  h2 {
    font-size: 1.3em !important; // 1.3em = 37px
    padding-bottom: 20px;
  }

  h3 {
    font-size: 1em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  button {
    font-family: $font-family-montserrat, sans-serif !important;
    color: $black-apple !important;
  }

  cite {
    font-size: 0.9em;
  }

  b {
    font-weight: 500;
  }

  img {
    display: block;
  }

  .section-standard-padding-left-right {
    padding-left: 15vw;
    padding-right: 15vw;
  }

  a {
    text-decoration: none;
    color: $purple;
    transition: 1s ease-out;
  }

  .mintgreen-underline {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 70%,
      $mint-green 30%,
      $mint-green
    );
    font-weight: 400;
  }

  .arrow-up-button-container {
    position: fixed;
    bottom: 6%;
    left: 50%;

    .up-button {
      padding: 6px 10px !important;
      color: $dark-grey-batman !important;
    }
  }
  /* END COMMON TO ALL PAGES */

  /* SECTIONS' HEIGHT AND PADDING */
  #terms-conditions {
    min-height: 80vh;
    min-height: 80dvh;
    padding-bottom: 5vh;
    padding-bottom: 5dvh;
    padding-top: 18vh;
    padding-top: 18dvh;
  }

  #studio {
    min-height: 70vh;
    min-height: 70dvh;
    padding-top: 30vh;
    padding-top: 30dvh;
  }

  #services,
  #clients,
  #mintfarben {
    min-height: 85vh;
    min-height: 85dvh;
    padding-top: 15vh;
    padding-top: 15dvh;
  }

  #contact {
    min-height: 75vh;
    min-height: 75dvh;
    padding-top: 15vh;
    padding-top: 15dvh;
  }
  /* END SECTION' HEIGHT AND PADDING */

  #terms-conditions {
    text-align: justify;
    h2 {
      padding-top: 20px;
      padding-bottom: 0;
    }
  }

  #not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    min-height: 80dvh;
    padding-top: 15vh;
    padding-top: 15dvh;
  }

  #homepage-main {
    section:nth-child(even) {
      background-color: $very-light-green-opacity-25;
    }

    h2 {
      font-family: $font-family-antonio !important;
      letter-spacing: 0.2em;
      font-size: 1.6em !important;
      line-height: 1.3em;
      margin-top: 0;
    }

    .home-content,
    .contact-content {
      text-align: justify;
    }

    .calendly-button {
      position: relative !important;
      background-color: $yellow-turbo;
      border-color: $very-light-green !important;
      border-radius: 25px !important;
      padding: 10px 25px;
      font-size: 0.8em; // 0.8em = 23px
      font-weight: 500 !important;
      box-shadow: 0 0 10px $black-opacity-25;
      transition: 0.5s;

      span {
        transition: 0.5s;
        color: $black-apple !important;
      }

      &:after {
        position: absolute;
        opacity: 0;
        top: 2;
        right: -12px;
        content: '\00bb';
        transition: 0.5s;
      }

      &:hover {
        padding-right: 35px;

        span {
          color: $black-opacity-70 !important;
        }

        &:after {
          padding-right: 14px;
          opacity: 1;
          right: 0;
        }
      }
    }

    .button-calendly-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    #services {
      .cards-services-container {
        display: grid;
        grid-template-rows: row;
        gap: 3em;

        .blurred {
          filter: blur(1px);
          -webkit-filter: blur(1px);
        }

        .single-card-container {
          position: relative;
          display: grid;
        }

        .card-style {
          background-color: $white;
          border-radius: 14px;
        }

        .services-card {
          min-width: 130px;
          grid-row: span 3;
          padding: 0 20px;
          transition: 0.5s;
          border: none !important;
          box-shadow: 0 0 10px $black-opacity-10;

          h3 {
            text-align: center;
            font-family: $font-family-antonio !important;
            font-size: 1.4em;
            color: $dark-grey-batman-opacity-80;
            margin-left: 0; // margin-left: 20px;
            width: fit-content;
            margin-bottom: 0px;

            background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0) 70%,
              $yellow-turbo 30%,
              $yellow-turbo
            );
          }

          ul {
            padding-left: 20px;

            li {
              display: flex;
              padding-bottom: 20px;
            }
          }

          .copywriting-buying-list {
            li:first-child:before {
              font-family: 'FontAwesome';
              content: '\e0b7';
              margin: 0 15px 0 -15px;
              color: $dark-grey-batman;
            }

            li:nth-child(2):before {
              font-family: 'FontAwesome';
              content: '\f0a1';
              margin: 0 15px 0 -15px;
              color: $dark-grey-batman;
            }

            li:nth-child(3):before {
              font-family: 'FontAwesome';
              content: '\f44b';
              margin: 0 15px 0 -15px;
              color: $dark-grey-batman;
            }

            li:nth-child(4):before {
              font-family: 'FontAwesome';
              content: '\f81d';
              margin: 0 15px 0 -15px;
              color: $dark-grey-batman;
            }
          }

          .seo-website-list {
            li:first-child:before {
              font-family: 'FontAwesome';
              content: '\f390';
              margin: 0 15px 0 -15px;
              color: $dark-grey-batman;
            }

            li:nth-child(2):before {
              font-family: 'FontAwesome';
              content: '\f54f';
              margin: 0 15px 0 -15px;
              color: $dark-grey-batman;
            }

            li:nth-child(3):before {
              font-family: 'FontAwesome';
              content: '\f201';
              margin: 0 15px 0 -15px;
              color: $dark-grey-batman;
            }
          }
        }

        .hidden-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .visible {
          opacity: 1;
        }
      }
    }

    #clients {
      .customers-reviews-container {
        display: grid;
        grid-template-rows: row;
        gap: 3em;
        width: 100%;

        .card-customer-review-container {
          grid-row: span 3;

          .customer-review-image {
            background-color: $black-apple;
            background-repeat: no-repeat;
            background-position: 0% 0%;
            background-size: cover;

            border-radius: 14px;
            min-height: 200px;
            width: 100%;
            transition: 0.5s;
            border: 1px solid $very-light-green-opacity-25;
            box-shadow: 0 0 10px $black-opacity-10;
            opacity: 0.85;

            &:hover {
              border: 1px solid $very-light-green-opacity-25;
              box-shadow: 0 0 10px $black-opacity-70;
              opacity: 1;
            }
          }

          #customer-1-background-image {
            background-image: url('../assets/studio-hna-website.png');
          }

          #customer-2-background-image {
            background-image: url('../assets/naturokos-website.webp');
          }

          #customer-3-background-image {
            background-image: url('../assets/franz-von-bueren-website.webp');
          }

          .customer-review-description {
            display: flex;
            padding-top: 20px;
            line-height: 1.2em !important;

            .clients-logo {
              width: 70px;
              margin: 0 auto;
              padding-right: 10px;
              opacity: 0.7;
              transition: 0.5s !important;

              &:hover {
                opacity: 0.9;
              }
            }

            h3 {
              margin: 10px 0px;
              font-size: 0.9em !important;
            }

            cite {
              text-align: center;
              font-size: 0.6em !important;
            }
          }

          .margin-bottom-card-customer {
            margin-bottom: 10px;
          }
        }
      }
    }

    #mintfarben {
      #mintfarben-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
        gap: 3em;

        .marilene-color-picture {
          width: 70%;
        }

        .mintfarben-content {
          text-align: justify;
          line-height: 1.2em !important;

          p:first-child {
            margin-top: 0 !important;
          }

          p:nth-child(2) {
            margin-bottom: 5px !important;
          }

          cite {
            text-align: center;
            font-size: 0.8em !important;
          }
        }
      }
    }

    #studio {
      .marilene-black-white-picture {
        width: 70%;
      }
    }

    #contact {
      .picture-text-container {
        .button-contact-container {
          margin-top: 30px;
        }
      }

      .contact-via-mail-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .contact-via-mail {
          font-size: 0.6em !important;
          padding: 8px 12px;
          border: none;

          background-color: $transparent !important;
          margin-top: 10px;

          a {
            color: $light-brown !important;
            transition: 0.5s ease-in-out;
          }

          .fa-paper-plane {
            transition: 0.5s ease-in-out;
          }

          &:hover {
            cursor: pointer;

            a {
              color: $brown !important;
              text-decoration: underline !important;
            }

            .fa-paper-plane {
              padding-left: 10px;
              transform: scale(1.2);
              color: $brown !important;
            }
          }
        }
      }
    }

    .picture-text-container {
      display: grid;
      gap: 3em;
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    }
  }

  #not-found {
    .back-homepage-button {
      transition: 0.1s;

      &:hover {
        background-color: $light-grey;

        a {
          &:hover {
            color: $very-light-grey !important;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  #main-body {
    #services,
    #clients,
    #mintfarben {
      padding-bottom: 5vh;
      padding-bottom: 5dvh;
    }

    .arrow-up-button-container {
      position: fixed;
      bottom: 4%;
      left: 86%;
    }
  }
}

@media (min-width: 800px) {
  #main-body {
    #mintfarben-container {
      grid-template-columns: 300px auto !important;
      gap: 0 !important;
      place-items: center;
      align-items: start;
    }
  }
}

@media (min-width: 820px) {
  #main-body {
    main {
      font-size: 1.5em; // 1.5em = 24px
    }

    #services {
      .services-card {
        h3 {
          margin-left: 20px !important;
        }

        ul {
          padding-left: 40px !important;
        }
      }
    }
  }
}

@media (max-width: 932px) {
  #main-body {
    #studio {
      padding-top: 20dvh;

      #logos-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .marilene-black-white-picture {
        max-width: 180px;
      }
    }
  }
}

@media (min-width: 1024px) {
  #main-body {
    main {
      line-height: 2em;
    }

    cite {
      line-height: 1.5em !important;
    }

    #services {
      .cards-services-container {
        grid-template-columns: repeat(auto-fit, minmax(370px, 1fr)) !important;
      }
    }

    #clients {
      .customers-reviews-container {
        grid-template-columns: repeat(auto-fit, minmax(370px, 1fr)) !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  #main-body {
    #studio {
      .marilene-black-white-picture {
        max-width: 300px;
      }
    }
  }
}

@media (min-width: 1360px) {
  #main-body {
    .marilene-color-picture {
      width: 60% !important;
    }

    .hidden-button {
      .calendly-button {
        min-width: 350px !important;
      }
    }
  }
}

@media (min-width: 1500px) {
  #main-body {
    main {
      font-size: 1.8em; // 1.8em = 28px
    }
  }
}

@media (min-width: 1360px) and (max-width: 1600px) {
  #main-body {
    #services {
      ul {
        font-size: 0.8em !important;
        line-height: 1.2em !important;
      }
    }

    #mintfarben {
      p,
      cite {
        line-height: 1.2em !important;
      }
    }

    #studio + .home-content,
    #contact {
      p {
        line-height: 1.2em !important;
      }
    }
  }
}

@media (min-width: 1601px) {
  #main-body {
    #services {
      ul {
        line-height: 1.5em !important;
      }
    }

    #studio,
    #contact {
      p {
        line-height: 1.5em !important;
      }
    }
  }
}

// @media (min-width: 1800px) {
//   #main-body {
//     #studio {
//       .services-montages-container {
//         min-width: 700px;

//         .services-montage {
//           min-width: 370px !important;
//           min-height: 370px !important;
//         }

//         .website-montage {
//           bottom: 200px !important;
//         }
//       }
//     }
//   }
// }

@media (min-width: 2000px) {
  #main-body {
    #services {
      ul {
        font-size: 1em !important;
      }
    }
  }
}
