@import '../../styles/variables';

#list-container {
  border: 1px black solid;
  border-radius: 20px;
  background-color: $purple;
  margin-bottom: 20px;
  width: 440px;
  
  button {
    background-color: $purple;
    border: none;
  }
}

.icon-trash {
  transition-duration: 0.5s;

  &:hover {
    cursor: pointer;
    color: $light-grey;
  }
}