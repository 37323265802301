@import '../../styles/variables';

footer {
  position: relative;
  bottom: 0;
  font-size: 0.8em;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;

    li {
      display: flex;
      width: 32%;
      justify-content: center;
      text-align: center;

      .theme-langage-container {
        justify-content: end !important;
      }

      .social-networks {
        display: flex;
        width: 20px;
      }

      a {
        text-decoration: none;
        color: $dark-grey-batman;
      }

      span {
        transition: 0.5s;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
          color: $light-grey-2;
        }
      }

      .fa-instagram {
        color: $red-watermelon;
        transition: 0.5s;

        &:hover {
          color: $yellow-turbo !important;
        }
      }

      .fa-linkedin-in {
        color: $dark-purple;
        transition: 0.5s;

        &:hover {
          color: $purple-2 !important;
        }
      }

      .fa-dice-six {
        color: $very-light-green;
        transition: 0.5s;

        &:hover {
          color: $dark-pink !important;
        }
      }
    }
  }
}
