@import '../../../styles/variables';

.dice-scoring-buttons-details {
  border: none;
  border-radius: 20px;
  padding: 0 9px;
  background-color: $very-light-green;
  border: 1px solid $transparent;
  transition-duration: 0.5s;
  transition-property: background-color;

  &:hover {
    cursor: pointer;
    background-color: $very-light-grey;
    border: 1px solid $very-light-green;
  }
}

.players-inputs {
  font-weight: 300;
  font-size: 1rem;
  width: 90px;
  border: 0.1px solid $transparent !important;
  border-radius: 15px;
  padding: 5px 10px;
  margin-left: 7px;
  transition-duration: 0.5s;
  transition-property: background-color;

  &:hover {
    background: $very-light-green;
  }

  &:focus-visible {
    border: 0.1px solid $light-grey-2 !important;
    background-color: $very-light-green;
  }

  &:invalid {
    border: 1.5px solid $medium-red;
    background-color: $dark-pink;
  }
}

// player top left
.player-item-0 {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 20px;
  margin-top: 20px;
}

// player top right
.player-item-1 {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 20px;
}

// player bottom left
.player-item-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 20px;
  margin-bottom: 20px;

  .dice-buttons {
    background-color: $very-light-blue;
  }

  .players-inputs {
    &:hover {
      background-color: $very-light-blue-2;
    }
  }
}

// player top right
.player-item-3 {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;

  .dice-buttons {
    background-color: $very-light-blue;
  }

  .players-inputs {
    &:hover {
      background-color: $very-light-blue-2;
    }
  }
}
